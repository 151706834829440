<template>
  <div class="subject_content">
    <div class="subject_both">
      <div class="both_head redgroup">
        <!-- @click="
          readdanci(
            'Un poco de aire magnífico, miles de kilómetros de viento rápido.'
          )
        " -->
        <img
          :src="myUserInfo.avatar ? myUserInfo.avatar : './images/right.png'"
          alt=""
        />
      </div>
      <div class="nickclass">
        <h1>{{ myUserInfo.nickName ? myUserInfo.nickName : "" }}</h1>
        <h1>{{ myAllScore }}</h1>
      </div>
      <div class="outcircle">
        <van-circle
          rate="100"
          :value="countdown * 10"
          :speed="100"
          :text="textnum"
          size="66px"
          color="#F7BB59"
          stroke-width="100"
          stroke-linecap="butt"
          default="font-size: 24px;
font-weight: 500;
color: rgba(29, 29, 31, 1);"
        />
      </div>
      <div class="nickclass">
        <h1>{{ battleUserInfo.nickName ? battleUserInfo.nickName : "" }}</h1>
        <h1>{{ battleScore }}</h1>
      </div>
      <div class="both_head bluegroup">
        <img
          :src="
            battleUserInfo.avatar ? battleUserInfo.avatar : './images/blue.png'
          "
          alt=""
        />
      </div>
    </div>
    <div class="score">
      <h2 class="score_red">
        {{ benlunScore ? "+" + benlunScore : "" }}
      </h2>
      <h2 class="score_blue">
        {{ battleResultMid.score ? "+" + battleResultMid.score : "" }}
      </h2>
    </div>
    <div class="subject">
      <div class="redpress-box">
        <div class="redpress" :style="{ height: blueHeight + 'px' }"></div>
      </div>
      <div :class="disabled ? 'disablestyle subjectcart' : 'subjectcart'">
        <p class="ratestyle">
          <span>{{ parseInt(num) + 1 }}</span
          >/{{ rate }}
        </p>
        <!-- //动词变位选择题 -->
        <div
          class="dinRegular textcenter"
          style="text-align: center"
          v-if="question.questionType == 'verb_change_sel'"
        >
          <div class="readbox">
            <p class="titletwo">{{ question.word }}</p>
            <img
              @click="readdanci(question.word)"
              :src="
                isShowGif
                  ? require('./images/read.gif')
                  : require('./images/read.png')
              "
              alt=""
            />
          </div>
          <p class="titlethree">{{ question.questionTitle }}</p>
        </div>
        <!-- 西到中发音选择题question.questionTitle -->
        <div
          class="dinRegular textcenter"
          style="text-align: center"
          v-else-if="question.questionType == 'sp_ch_read_sel'"
        >
          <img
            class="readimg"
            :src="
              isShowGif
                ? require('./images/read.gif')
                : require('./images/readbig.png')
            "
            alt=""
            @click="readdanci(question.word)"
          />
          <p class="titlethree"></p>
        </div>
        <!-- //图片到西语选择题 -->
        <div
          class="titleimg"
          v-else-if="question.questionType == 'image_sp_sel'"
        >
          <img :src="baseUrl + question.questionTitle" alt="" />
        </div>
        <!-- else -->
        <div class="readbox" v-else>
          <h4
            :class="
              question.questionType == 'ch_sp_sel'
                ? 'titleone textcenter'
                : question.questionType == 'example_sel'
                ? 'titlefour dinRegular'
                : 'titletwo dinRegular textcenter'
            "
          >
            {{ question.questionTitle }}
          </h4>
          <img
            v-if="
              question.questionType == 'sp_ch_sel' ||
              question.questionType == 'sp_image_sel'
            "
            @click="readdanci(question.questionTitle)"
            :src="
              isShowGif
                ? require('./images/read.gif')
                : require('./images/read.png')
            "
            alt=""
          />
        </div>
        <!-- v-if="question.questionType == 'sp_image_sel'" -->
        <!-- <div class="imgbox">
          <div class="imgoption wrongmask wrongiconSel">
            <img src="./images/option.png" alt="" />
          </div>
          <div class="imgoption rightmask righticonSel">
            <img src="./images/option.png" alt="" />
          </div>
        </div> -->
        <div class="imgbox" v-if="question.questionType == 'sp_image_sel'">
          <div
            v-for="(queitem, index) in question.questionOptionList"
            :key="queitem"
            class="imgoption"
            @click="chooseimg(index)"
            :class="{
              rightmask:
                (rightOption != '' || rightOption === 0) &&
                rightOption == index,
              wrongmask:
                ((option != '' || option == '0') &&
                  option == index &&
                  !myResult.result) ||
                (battleResult.isAnswer &&
                  battleResult.option == index &&
                  !battleResult.result),
              righticonUser:
                battleResult.isAnswer &&
                battleResult.option == index &&
                battleResult.result,
              wrongiconUser:
                battleResult.isAnswer &&
                battleResult.option == index &&
                !battleResult.result,
              righticonSel:
                myResult.isAnswer &&
                (option != '' || option == '0') &&
                option == index &&
                myResult.result,
              wrongiconSel:
                myResult.isAnswer &&
                (option != '' || option == '0') != '' &&
                option == index &&
                !myResult.result,
            }"
          >
            <img :src="baseUrl + queitem" alt="" />
          </div>
        </div>
        <el-radio-group v-model="option" v-else>
          <el-radio-button
            v-for="(queitem, index) in question.questionOptionList"
            :key="queitem"
            :label="index"
            :class="{
              right:
                (rightOption != '' || rightOption === 0) &&
                rightOption == index,
              wrong:
                ((option != '' || option == '0') &&
                  option == index &&
                  !myResult.result) ||
                (battleResult.isAnswer &&
                  battleResult.option == index &&
                  !battleResult.result),
              battalright:
                battleResult.isAnswer &&
                battleResult.option == index &&
                battleResult.result,
              battalwrong:
                battleResult.isAnswer &&
                battleResult.option == index &&
                !battleResult.result,
              myright:
                myResult.isAnswer &&
                (option != '' || option == '0') &&
                option == index &&
                myResult.result,
              mywrong:
                myResult.isAnswer &&
                (option != '' || option == '0') != '' &&
                option == index &&
                !myResult.result,
            }"
            >{{ queitem }}</el-radio-button
          >
        </el-radio-group>
        <!-- <el-radio-group>
          <el-radio-button class="myright"></el-radio-button>
          <el-radio-button class="mywrong"></el-radio-button>
          <el-radio-button></el-radio-button>
          <el-radio-button></el-radio-button>
        </el-radio-group> -->
      </div>
      <div class="bluepress-box">
        <div class="bluepress" :style="{ height: redHeight + 'px' }"></div>
      </div>
    </div>
  </div>
</template>


<script>
import {
  question, submitSelfResult, getQuestionBattleResult
} from "@/api"
import { Circle, Notify } from 'vant'
import 'vant/lib/index.css'
// import * as sdk from "microsoft-cognitiveservices-speech-sdk"
export default {
  name: "BattleSubject",
  components: {
    "van-notify": Notify,
    [Circle.name]: Circle,

  },
  data () {
    return {
      baseUrl: window.__baseUrl__,
      disabled: false,
      rate: 10,
      battleId: '180',
      num: '0', //第几题从0开始

      question: {},

      myUserInfo: {
        avatar: '',
        nickName: ''
      },
      battleUserInfo: {
        avatar: '',
        nickName: ''
      },

      countdown: 10,
      timer: '',
      option: '',
      rightOption: '',

      benlunScore: '',//本轮我得分
      myAllScore: 0,//wo总得分情况
      battleScore: 0,//对方总分数

      myResult: {},
      battleResult: {},
      battleResultMid: {},

      timertwo: '',//计时器三秒后再获取下一题目

      everyHeight: 0, //
      redHeight: 30,
      blueHeight: 30,
      currentRate: 5,
      isEnd: false,
      isAnswerMy: false,//我是否答题
      SpeechSDK: {},
      isShowGif: false
    }
  },
  mounted () {
    // console.log(this.$route, 'this.$route')
    const { query } = this.$route
    this.battleId = query.battleId
    this.battleUserInfo = query.battleUserInfo ? JSON.parse(query.battleUserInfo) : {}
    this.myUserInfo = query.myUserInfo ? JSON.parse(query.myUserInfo) : {}
    this.getquestion()


    // this.SpeechSDK = window.SpeechSDK
    this.SpeechSDK = require("microsoft-cognitiveservices-speech-sdk")



  },
  computed: {
    textnum () {
      return (this.countdown) + ""
    },

    dataChange () {
      const { battleResultMid, isAnswerMy } = this
      return { battleResultMid, isAnswerMy }
    }


  },
  methods: {
    readdanci (textspeak) {
      var that = this
      this.isShowGif = true
      var synthesizer
      var speechConfig = this.SpeechSDK.SpeechConfig.fromSubscription(process.env.VUE_APP_SPEECH_KEY, process.env.VUE_APP_SPEECH_REGION)
      speechConfig.speechSynthesisVoiceName = "es-ES-ElviraNeural"
      synthesizer = new this.SpeechSDK.SpeechSynthesizer(speechConfig)

      // let inputText = phraseDiv.value
      synthesizer.speakTextAsync(
        textspeak,
        function (result) {
          that.isShowGif = false
          // window.console.log(result)
          synthesizer.close()
          synthesizer = undefined

        },
        function (err) {
          // window.console.log(err)
          that.isShowGif = false
          synthesizer.close()
          synthesizer = undefined
        })

    },
    chooseimg (val) {
      this.option = val
    },
    jishi () {
      this.timer = setInterval(() => {

        this.countdown -= 1
        if (this.countdown == 0) {   //超时关掉倒计时功能
          clearInterval(this.timer)
        }

      }, 1000)
    },
    getquestion () {
      console.log(this.battleId)
      question({ battleId: this.battleId, num: this.num }).then((d) => {

        if (d.code == 200) {
          this.disabled = false
          this.isAnswerMy = false
          this.myResult = {}
          this.battleResult = {}
          this.battleResultMid = {}
          this.benlunScore = ''
          this.rightOption = ''
          this.option = ''
          this.isEnd = d.data.isEnd

          if (d.data.isEnd) { //每题了跳转到result页面
            this.$router.push({ name: 'result', params: { battleResult: JSON.stringify(d.data.battleResult) } })
          } else {

            this.countdown = 10
            this.jishi()
            this.question = d.data.question
            //this.question.questionOption = JSON.parse(this.question.questionOption)
            if (this.num == 0) {
              this.rate = d.data.questionNum
              this.everyHeight = Math.floor(500 / this.rate) //每段高度
            }
            this.currentRate = 100 / this.rate * (parseInt(this.num) + 1)
            // console.log(this.currentRate, '100 / this.rate')

            //获取对方答题情况
            getQuestionBattleResult({ battleId: this.battleId, num: this.num }).then((d) => {
              if (d.code == 200) {
                this.battleScore += d.data.score//对方分数
                this.battleResultMid = d.data//对方答题情况 
                if (d.data.result) {
                  this.redHeight += this.everyHeight
                }
                console.log('getquestion1')


              } else {
                this.$notify(d.msg)
              }
            })
          }

        } else {
          this.$notify(d.msg)
        }
      })



    },

    // 答题
    submitSelfResult (isAnswer) {
      this.disabled = true
      this.rightOption = this.question.questionAnswer
      let result = (this.option || this.option == '0') && this.option == this.question.questionAnswer ? true : false
      this.myResult = {
        result: result,
        isAnswer: isAnswer,
      }
      let answerquery = {
        battleId: this.battleId,
        questionId: this.question.id,
        num: this.num,
        useTime: 10 - this.countdown,
        result: result,
        option: this.option,
        isAnswer: isAnswer

      }
      if (this.timer) clearInterval(this.timer)
      submitSelfResult(answerquery).then((d) => {
        if (d.code == 200) {
          this.myAllScore += d.data
          if (result) this.blueHeight += this.everyHeight
          this.benlunScore = d.data
          this.isAnswerMy = true
        }
      })
    },





  },
  watch: {
    option (newVal, oldVal) {
      if (newVal != '' || newVal == '0') {
        console.log(1)
        this.submitSelfResult(true)
      }
    },
    countdown (newVal, oldVal) {

      if (newVal == 0 && this.option == '' && this.option != '0') {
        console.log(2)
        this.submitSelfResult(false)

      }

    },
    dataChange (newVal, oldVal) {
      // (newVal.benlunScore != '' || newVal.benlunScore == 0)
      if (newVal.isAnswerMy && (JSON.stringify(newVal.battleResultMid) != '{}')) {
        //对方答题情况展示
        this.battleResult = this.battleResultMid//对方是否答对
        // if (this.battleResult.isAnswer) {
        this.timertwo = setTimeout(() => {
          this.question = {}
          this.num = String(parseInt(this.num) + 1)
          this.getquestion()
          clearTimeout(this.timertwo)
        }, 1000)
        // } else {
        //   this.timertwo = setTimeout(() => {
        //     this.question = {}
        //     this.num = String(parseInt(this.num) + 1)
        //     this.getquestion()
        //     clearTimeout(this.timertwo)
        //   }, 1000)
        // }
      }

    }

  }


}
</script>

<style lang="less" scoped>
@import url(../../assets/css/fontcss.css);
.readimg {
  width: 48px;
  height: 48px;
  cursor: pointer;
}
.textcenter {
  text-align: center;
}
/deep/.el-radio-button__inner {
  white-space: normal;
}
.disablestyle {
  pointer-events: none;
}

/deep/.el-radio-button {
  width: 100%;
  // height: 48px;
  opacity: 1;
  border-radius: 8px;
  background: #e5fbf3;
  margin-top: 28px;
  font-family: "din-regular";
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 1.5;
  color: rgba(29, 29, 31, 1);
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
/deep/.el-radio-group {
  width: 100%;
}
/deep/.el-radio-button__inner {
  border: none;
  background: none;
  // overflow: hidden;
}
/deep/.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  width: 100%;
  // height: 48px;
  color: rgba(29, 29, 31, 1);
  // background-color: rgba(87, 212, 137, 1);
  // border-color: rgba(87, 212, 137, 1);
  // box-shadow: -1px 0 0 0 rgba(87, 212, 137, 1);
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  border-radius: 8px;
}
/deep/.el-radio-button__inner,
.el-radio-group {
  display: block;
}
/deep/.el-radio-button__inner {
  width: 100%;
  line-height: 1.5;
  padding: 17px;
  border: none;
}
/deep/.el-radio-button:first-child .el-radio-button__inner {
  border: none;
}
.imgbox {
  // width: 100%;
  max-width: 316px;
  // height: 316px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .imgoption {
    // width: 48%;
    // height: auto;
    width: 123.42px;
    height: 123.42px;
    margin-top: 15px;
    position: relative;
    img {
      border-radius: 4px;
      width: 100%;
      height: 100%;
    }
  }
  .wrongmask::before {
    content: "";
    // display: block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 1;
    border-radius: 4px;
    background: rgba(238, 151, 143, 0.8);
  }
  .rightmask::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;

    width: 100%;
    height: 100%;
    opacity: 1;
    border-radius: 4px;
    background: rgba(187, 230, 198, 0.8);
  }
  .righticonUser::after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 44px;
    height: 44px;
    z-index: 99;
    background: url("./images/opright.png") no-repeat;
  }
  .wrongiconUser::after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 44px;
    height: 44px;
    z-index: 99;
    background: url("./images/opwrong.png") no-repeat;
  }
  .righticonSel::after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 99;
    width: 44px;
    height: 44px;
    background: url("./images/opright.png") no-repeat;
  }
  .wrongiconSel::after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 44px;
    height: 44px;
    z-index: 99;
    background: url("./images/opwrong.png") no-repeat;
  }
}
.subject_content {
  transition: all 0.6s ease;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(229, 251, 243, 1) 0%,
    rgba(241, 255, 239, 1) 60.72%,
    rgba(247, 247, 247, 1) 100%
  );
  padding: 40px 5.1vw 20px;
  .subject_both {
    width: 100%;
    height: 66px;
    padding: 0 10px;
    opacity: 1;
    border-radius: 33px;
    background-color: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .both_head {
      width: 48px;
      height: 48px;
      opacity: 1;
      border-radius: 50%;
    }
    .redgroup {
      border: 2px solid rgba(241, 77, 114, 1);
      img {
        border-radius: 50%;
      }
    }
    .bluegroup {
      border: 2px solid rgba(0, 119, 255, 1);
      img {
        border-radius: 50%;
      }
    }

    .outcircle {
      width: 74px;
      height: 74px;
      opacity: 1;
      border-radius: 50%;
      background: rgba(229, 251, 243, 1);
      text-align: center;
      padding-top: 4px;

      // border: 11px solid rgba(255, 255, 255, 1);
    }
    .nickclass {
      flex: 1;
      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //用省略号显示
      white-space: nowrap; //不换行
      margin: 0 5px;
    }
    h1 {
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 25.2px;
      color: rgba(29, 29, 31, 1);
      text-align: center;
    }
  }

  .score {
    width: 100%;
    height: 26px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    // line-height: 20px;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    .score_red {
      color: rgba(245, 89, 126, 1);
      margin-left: -10px;
    }
    .score_blue {
      color: rgba(0, 119, 255, 1);
      margin-right: -10px;
    }
  }
  .subject {
    // height: 530px;
    margin-top: 11px;
    display: flex;
    justify-content: space-between;
    .redpress-box {
      width: 6px;
      height: 530px;
      margin-top: -16px;
      margin-left: 4px;
      background-color: #fff;
      position: relative;
      .redpress {
        width: 100%;
        // height: 104px;
        opacity: 1;
        border-radius: 221px;
        background: linear-gradient(180deg, #f04d71 0%, #f4567b 100%);
        position: absolute;
        bottom: 0;
      }
    }
    .bluepress-box {
      width: 6px;
      height: 530px;
      margin-right: 4px;
      margin-top: -16px;
      background-color: #fff;
      position: relative;
      .bluepress {
        width: 100%;
        // height: 52px;
        opacity: 1;
        border-radius: 221px;
        background: linear-gradient(180deg, #0077ff 0%, #57a5ff 100%);
        position: absolute;
        bottom: 0;
      }
    }
    .subjectcart {
      flex: 1;
      width: 100%;
      //height: 460px;
      margin: 0 15px;
      border-radius: 12px;
      background: #ffffff;
      backdrop-filter: blur(20px);
      padding: 24px 25px 60px;
      .ratestyle {
        text-align: right;
        margin-right: -5px;
      }
      .titleimg {
        width: 100px;
        // max-width: 100%;
        height: 100px;
        // text-align: center;
        margin: 0 auto;

        img {
          width: 100%;
          height: 100%;
          border-radius: 4px;
        }
      }

      .titleone {
        font-size: 24px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 33.6px;
        color: rgba(29, 29, 31, 1);
        text-align: center;
        // margin-bottom: 50px;
      }
      .readbox {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img {
          width: 24px;
          height: 24px;
          margin-left: 3px;
        }
      }
      .titletwo {
        font-size: 24px;
        font-weight: 500;
        line-height: 43.6px;
        color: rgba(29, 29, 31, 1);
      }
      .titlethree {
        font-size: 12px;
        font-weight: 500;
        line-height: 16.8px;
        color: rgba(87, 212, 137, 1);
      }
      .titlefour {
        font-size: 18px;
        font-weight: 500;
        line-height: 21.8px;
        color: rgba(29, 29, 31, 1);
      }
      .subjectitem {
        width: 100%;
        // height: 48px;
        opacity: 1;
        border-radius: 8px;
        background: #e5fbf3;
        margin-top: 28px;
        white-space: normal;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 1.5;
        color: rgba(29, 29, 31, 1);
        text-align: center;
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
      .right {
        background: #57d489;
      }
      .wrong {
        background: #fc7f74;
      }
      .battalright::after {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        background-image: url("./images/newright.png");
        background-size: 100% 100%;
        margin-right: -20px;
      }

      .battalwrong::after {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        background-image: url("./images/newclose.png");
        background-size: 100% 100%;
        margin-right: -20px;
      }
      .myright::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        background-image: url("./images/newright.png");
        background-size: 100% 100%;
        margin-left: -20px;
      }

      .mywrong::before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        background-image: url("./images/newclose.png");
        background-size: 100% 100%;
        margin-left: -20px;
      }
    }
  }
}
</style>